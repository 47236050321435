import './App.css';
import { HashLink as Link } from 'react-router-hash-link';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter
} from 'react-router-dom';

import Hero from './pages/Hero';
import About from './pages/About';
import Facts from './pages/Facts';
import Resume from './pages/Resume';
function App() {
  return (
    <BrowserRouter>
      <header id="header">
        <div class="d-flex flex-column">

          <div class="profile">
            <img src="assets/img/profile-img-2.jpg" alt="" class="img-fluid rounded-circle"/>
            <h1 class="text-light"><a href="index.html">LZY</a></h1>
            <div class="social-links mt-3 text-center">
              <a href="https://www.instagram.com/zongyuan_1012/" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.linkedin.com/in/lee-z-3891831ba/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              <a href='https://github.com/leebuffett' class="github"><i class="bx bxl-github"></i></a>
            </div>
          </div>

          <nav id="navbar" class="nav-menu navbar">
            <ul>
              <li>
                <Link to="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></Link>
              </li>
              <li>
                <Link to="#about" class="nav-link scrollto"><i class="bx bx-user"></i> <span>About</span></Link>
              </li>
              <li>
                <Link to="#resume" class="nav-link scrollto"><i class="bx bx-file-blank"></i> <span>Resume</span></Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <Hero />
      <About />
      <Facts />
      <Resume />

  </BrowserRouter>
  );
}

export default App;
