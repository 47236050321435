import React, { Component } from 'react';
import Typed from 'typed.js';

class Hero extends Component {
    componentDidMount() {
        const options = {
          strings: ['I\'m LZY','I\'m Developer', 'I\'m Programmer', 'I\'m a Hooper'],
          typeSpeed: 150,
          loop: true,
          loopCount: Infinity,
        };
        this.typed = new Typed(this.el, options);
      }
    
      componentWillUnmount() {
        this.typed.destroy();
      }
  render() {
    return (
        <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
          <div class="hero-container" data-aos="fade-in">
            <h1>LZY</h1>
            <p><span className="typed" ref={(el) => { this.el = el; }}></span></p>
          </div>
        </section>
    );
  }
}
 
export default Hero;