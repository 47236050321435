import React, { Component } from 'react';
import Typed from 'typed.js';

class About extends Component {
  render() {
    return (
        <main id="main">
        <section id="about" class="about">
          <div class="container">
    
            <div class="section-title">
              <h2>About</h2>
              <p>
                A handsome boy.
                Graduated from TARUC, majored in Information Security.
                Currently working as Software Engineer.
                Logic is my passion, and love to solve problems.
                Fast learner, and willing to learn new things.
                Team player, willing to help others.
                
                My interest does not limit only in Programming/Coding, just like this site is an achievement with
                my one of my friend who is AWS Cloud Engineer/Devops, learn how to use ReactJS and deploy to AWS S3.
                As now I am venturing into cloud computing such as Lambda and API Gateway.
              </p>
            </div>
    
            <div class="row">
              <div class="col-lg-4" data-aos="fade-right">
                <img src="assets/img/profile-img-2.jpg" class="img-fluid" alt=""/>
              </div>
              <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                <h3>Software Engineer &amp; Programmer.</h3>
                <p class="fst-italic">
                  Below are some details about me
                </p>
                <div class="row">
                  <div class="col-lg-6">
                    <ul>
                        <li>
                        <i class="bi bi-chevron-right"></i> <strong>Website:</strong> 
                        <span>https://lzy.devopsvlog.com</span>
                        </li>
                        <li>
                        <i class="bi bi-chevron-right"></i> <strong>Website:</strong> 
                        <span>https://www.devopsvlog.com</span>
                        </li>
                      {/* <li><i class="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+123 456 7890</span></li> */}
                      <li><i class="bi bi-chevron-right"></i> <strong>City:</strong> <span>Kuala Lumpur, Malaysia</span></li>
                    </ul>
                  </div>
                  <div class="col-lg-6">
                    <ul>
                      <li><i class="bi bi-chevron-right"></i> <strong>Year Born:</strong> <span>1999</span></li>
                      <li><i class="bi bi-chevron-right"></i> <strong>Highest Education:</strong> <span>Degree</span></li>
                      {/* <li><i class="bi bi-chevron-right"></i> <strong>PhEmailone:</strong> <span></span></li>
                      <li><i class="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </main>

    );
  }
}
 
export default About;