import React, { Component } from 'react';

class Resume extends Component {
  render() {
    return (
        <main id="main">
            <section id="resume" class="resume">
                <div class="container">

                    <div class="section-title">
                        <h2>Resume</h2>
                        <p>Quick Resume about my Education level and Work Experience</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6" data-aos="fade-up">
                            <h3 class="resume-title">Sumary</h3>
                            <div class="resume-item pb-0">
                                <h4>Zong Yuan</h4>
                                <p>
                                    <em>
                                        Creative and Initiative Software Engineer with 2 years plus of experience 
                                        in C# .Net Framework, and some others programmings language.
                                        Support and deliver user request on programmings/coding related issues.
                                    </em>
                                </p>
                                <ul>
                                    <li>zongyuanlee168@gmail.com</li>
                                </ul>
                            </div>

                            <h3 class="resume-title">Education</h3>
                            <div class="resume-item">
                                <h4>Bachelor Degree in Science &amp; Information Security</h4>
                                <h5>2019 - 2021</h5>
                                <p><em>Tunkun Abdul Rahman University of Management and Technology, Kuala Lumpur, MY</em></p>
                                <ul>
                                    <li>Grade: 3.76 (First Class)</li>
                                    <li>study fundamental of cybersecurity, networking, programmings, and application development</li>
                                </ul>
                            </div>
                            <div class="resume-item">
                                <h4>Diploma in Information Technology</h4>
                                <h5>2017 - 2019</h5>
                                <p><em>Tunkun Abdul Rahman University of Management and Technology, Kuala Lumpur, MY</em></p>
                                <ul>
                                    <li>Grade: 3.77 (First Class)</li>
                                    <li>study the fundamental computing techniques and develop the computing and information technology-based knowledge and skills required </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="resume-title">Professional Experience</h3>
                            <div class="resume-item">
                                <h4>Software Engineer</h4>
                                <h5>June 2021 - July 2023</h5>
                                <p><em>Nettium Sdn. Bhd, Kuala Lumpur, MY </em></p>
                                <ul>
                                    <li>Work under Backend CRM team</li>
                                    <li>Write clean, well-structured and maintainable code using C# best practices</li>
                                    <li>Built scalable and high-performance web application/API/Console Application along with tools such as Azure Cloud</li>
                                    <li>Provide first level support as needed, diagnosing issues and performing bug fixes / software maintenance or configuration</li>
                                    <li>Guide teammate for the team modules and provide guidance to new joiners and interns</li>
                                    <li>Create Postman Scripts For REST API</li>
                                </ul>
                            </div>
                            <div class="resume-item">
                                <h4>Internship Software Engineer</h4>
                                <h5>January 2021 - June 2021</h5>
                                <p><em>Nettium Sdn. Bhd, Kuala Lumpur, MY </em></p>
                                <ul>
                                    <li>Involved in online event related C# project.</li>
                                    <li>Involved in Frontend developmentt (Translate mockups into working static web page by using Html, Css, ZURB Foundation, JavaScript, Ajax,jQuery and localizations technology)</li>
                                    <li>Involved in SEO and Piwik implementation</li>
                                    <li>Using WebSocket technology to get the real-time data</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
  }
}
 
export default Resume;